<template>
    <v-container>
        <v-card>
            <v-card-title>Mon profil</v-card-title>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 primary--text">
                        {{ $t('users.attributes.theme') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-card-text>
                <v-container>
                    <v-radio-group v-model="myprofile.theme" row>
                        <v-radio :label="$t('users.theme.light')" value="light"></v-radio>
                        <v-radio :label="$t('users.theme.dark')" value="dark"></v-radio>
                    </v-radio-group>
                </v-container>
            </v-card-text>

            <v-divider class="mx-8"></v-divider>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 primary--text">
                        {{ $t('users.attributes.notification') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-card-text>
                <v-container>
                    <v-radio-group v-model="myprofile.notification" row>
                        <v-radio :label="$t('users.notification.email')" value="email"></v-radio>
                        <v-radio :label="$t('users.notification.team')" value="team"></v-radio>
                        <!--<v-radio :label="$t('users.notification.push')" value="push"></v-radio>-->
                    </v-radio-group>
                </v-container>
            </v-card-text>

            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-2 primary--text">
                        {{ $t('users.attributes.defaultPage') }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-card-text>
                <v-container>
                    <v-radio-group v-model="myprofile.defaultPage" row>
                        <v-radio
                            v-for="page in availablesPages"
                            :key="page.value"
                            :label="page.text"
                            :value="page.value"
                        ></v-radio>
                    </v-radio-group>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn color="red" text class="mr-4" @click="cancelEdit">
                    {{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn color="success" class="mr-4" @click="submitForm">
                    {{ $t('users.actions.update') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Clone from 'lodash/cloneDeep';

export default {
    name: 'user-profile',
    data: () => ({
        myprofile: {
            theme: 'light',
        },
    }),
    mounted() {
        this.myprofile = Clone(this.profile);
    },
    watch: {},
    computed: {
        ...mapGetters(['profile']),
        availablesPages() {
            let pages = [];

            pages.push({ value: 'dashboard', text: this.$t('menu.dashboard') });
            if (this.profile.security.packsAccess || this.profile.security.packsCreate) {
                pages.push({ value: 'packs', text: this.$t('menu.packs') });
            }
            if (this.profile.security.projects) {
                pages.push({ value: 'projects', text: this.$t('menu.projects') });
            }
            if (this.profile.security.sprints) {
                pages.push({ value: 'teams', text: this.$t('menu.teams') });
            }
            if (this.profile.security.timesAccess) {
                pages.push({ value: 'times', text: this.$t('menu.times') });
            }
            pages.push({ value: 'calendar', text: this.$t('menu.calendar') });
            if (this.profile.security.knowledgeAccess) {
                pages.push({ value: 'knowledges', text: this.$t('menu.knowledges') });
            }

            return pages;
        },
    },
    methods: {
        ...mapActions(['updateMyProfile', 'addSubscription', 'showError']),
        cancelEdit() {
            this.$router.push({ name: 'dashboard' });
        },
        urlBase64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
            const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
        async checkPermission() {
            const permission = await Notification.requestPermission();
            return permission;
        },
        async askPushNotification() {
            const registration = await navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`);
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: this.urlBase64ToUint8Array(process.env.VUE_APP_PUSH_CLIENT),
            });
            this.addSubscription({ id: this.profile.id, subscription: subscription });
        },
        async submitForm() {
            if (this.myprofile.notification === 'push') {
                const permission = await this.checkPermission();

                if (permission === 'granted') {
                    await this.askPushNotification();
                } else if (permission === 'denied') {
                    this.showError(this.$t('users.actions.error_notification'));
                    return;
                }
            }

            const user = {
                id: this.myprofile.id,
                theme: this.myprofile.theme,
                notification: this.myprofile.notification,
                defaultPage: this.myprofile.defaultPage,
            };

            this.updateMyProfile(user);
        },
    },
};
</script>
